module.exports = [{
      plugin: require('../../../../../common/temp/node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_encoding@0.1.13_react-dom@18.3.1_react@18.3.1__re_7wihacpw2gqk5bz3tsxrezoapa/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"87521e0d5fa8ae4c939f0229b7541cd6"},
    },{
      plugin: require('../../../../../libs/gatsby-plugin-react-i18next-root/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"fr","i18nextOptions":{"defaultNS":"common","fallbackLng":"fr","interpolation":{"escapeValue":false},"supportedLngs":["fr","en"]},"languages":["fr","en"],"siteUrl":"https://uat-app.locksbook.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../common/temp/node_modules/.pnpm/gatsby@5.14.1_encoding@0.1.13_react-dom@18.3.1_react@18.3.1__react@18.3.1_type-fest@4.37.0_typescript@5.8.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
